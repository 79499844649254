import { DefaultLayout } from "@/modules/core/views/templates";
export default [
  {
    name: "PageNotFound",
    path: "*",
    component: DefaultLayout,
    redirect: "/404",
    children: [
      {
        meta: {
          auth: false
        },
        path: "/404",
        component: () => import("@/modules/core/views/pages/NotFound.vue")
      },
      {
        name: "redirectSSO",
        path: "/redirecionar-login",
        meta: {
          auth: false
        },
        beforeEnter(to, from, next) {
          window.location.href =
            process.env.VUE_APP_SSO_URL + "Account/Login?Logout=true";
        }
      }
    ]
  }
];
